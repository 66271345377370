import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";

import BlogPost from "components/BlogPost";
import SectionBlogSuggestions from "components/SectionBlogSuggestions";
import SEO, { createQueryImageFromFile } from "components/SEO";

export const pageQuery = graphql`
  fragment PostAuthor on MarkdownRemark {
    id
    frontmatter {
      name
      email
      title
      social {
        url
      }
      avatar {
        image {
          ...AvatarImageGreyscale
        }
        alt
        caption
      }
    }
  }

  fragment BlogPost on MarkdownRemark {
    html
    frontmatter {
      title
      date
      subtitle
      tags
      # Uncomment when implemented in at least 1 entry
      # featuredImage {
      #   image {
      #     ...OpenGraphImage
      #     ...TwitterImage
      #     ...StructuredDataImage
      #   }
      #   alt
      #   caption
      # }
      # See the mapping in gatsby-config
      author {
        ...PostAuthor
      }
    }
  }

  query Post($id: String!, $tags: [String]!, $relatedPostSlugs: [String]!) {
    post: markdownRemark(id: { eq: $id }) {
      ...BlogPost
      # Extra fields needed for SEO. TODO: clearer logic
      excerpt(pruneLength: 160)
      frontmatter {
        description
        thumbnail {
          image {
            ...OpenGraphImage
            ...TwitterImage
            ...StructuredDataImage
          }
          alt
          caption
        }
      }
      fields {
        path
        dateModified
      }
    }
    similarPosts: allMarkdownRemark(
      limit: 3
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        id: { ne: $id }
        frontmatter: { template: { eq: "post" }, tags: { in: $tags } }
      }
    ) {
      edges {
        node {
          ...BlogPostThumbnail
        }
      }
    }
    relatedPosts: allMarkdownRemark(
      limit: 3
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        id: { ne: $id }
        frontmatter: {
          template: { eq: "post" }
          slug: { in: $relatedPostSlugs }
        }
      }
    ) {
      edges {
        node {
          ...BlogPostThumbnail
        }
      }
    }
  }
`;

/**
 * @method
 *
 * @param {{node: GatsbyTypes.BlogPostThumbnailFragment}} edge
 *
 * @returns {GatsbyTypes.BlogPostThumbnailFragment}
 */
function takeOutNode({ node }) {
  return node;
}

/**
 * @typedef {object} Props
 * @prop {Location} location
 * @prop {GatsbyTypes.PostQuery} data
 *
 * @param {Props} props
 */
export default function Post({
  location,
  data: {
    post,
    similarPosts: { edges: similarPosts },
    relatedPosts: { edges: relatedPosts },
  },
}) {
  const {
    excerpt,
    frontmatter: {
      title,
      description,
      subtitle,
      // featuredImage,
      thumbnail,
      date,
      tags,
    },
    fields: { dateModified },
  } = post;

  const {
    id: authorId,
    frontmatter: { avatar, ...authorData },
  } = post.frontmatter.author;

  const [
    openGraph,
    twitter,
    sdPrimaryImage,
  ] = createQueryImageFromFile(thumbnail, [
    "openGraphImage.resize",
    "twitterImage.resize",
    "sdImage.resize",
  ]);

  const [authorAvatar] = createQueryImageFromFile(avatar, ["image.resize"]);

  const similarBlogPostThumbnails = similarPosts.map(takeOutNode);
  const relatedBlogPostThumbnails = relatedPosts.map(takeOutNode);

  return (
    <Layout>
      <SEO
        title={title}
        description={subtitle || description || excerpt}
        isArticle
        pathname={location.pathname}
        dateModified={new Date(dateModified)}
        datePublished={new Date(date)}
        postTags={tags}
        images={{ openGraph, twitter, sdPrimaryImage }}
        author={{
          id: authorId,
          email: authorData.email,
          name: authorData.name,
          social: authorData.social || [],
          description: authorData.title,
          avatar: authorAvatar,
        }}
      />
      <BlogPost {...post} />
      <SectionBlogSuggestions
        blogPosts={[...similarBlogPostThumbnails, ...relatedBlogPostThumbnails]}
      />
    </Layout>
  );
}
