import React from "react";
import dayjs from "dayjs";
import { Row, Col } from "antd";

import Image from "components/Image";
import RichText from "components/RichText";
import BlogPostTags from "components/BlogPostThumbnail/BlogPostTags";

import styles from "./BlogPost.module.less";

/**
 * @typedef {object} Props
 * @param {GatsbyTypes.BlogPostFragment} props
 */
export default function BlogPost(props) {
  const {
    html,
    frontmatter: { subtitle, title, tags, date, author },
  } = props;

  return (
    <article className={styles.container}>
      <Row justify="center">
        <Col lg={14} xs={24}>
          <header className={styles.header}>
            <h1 className={styles.title}>{title}</h1>
            <Row
              justify="center"
              align="middle"
              gutter={{
                sm: 12,
                xs: 6,
              }}
            >
              <Col>
                <Image
                  className={styles.avatar}
                  src={author.frontmatter.avatar.image.avatar}
                  publicURL={author.frontmatter.avatar.image.publicURL}
                  alt={author.frontmatter.name}
                />
              </Col>
              <Col className={styles.authorContainer}>
                <div className={styles.authorName}>
                  {author.frontmatter.name}
                </div>
                <div className={styles.authorTitle}>
                  {author.frontmatter.title} at Inex One
                </div>
                <div className={styles.legend}>
                  <time dateTime={dayjs(date).format("YYYY-MM-DD")}>
                    {dayjs(date).format("dddd, MMMM D, YYYY")}
                  </time>{" "}
                  - <BlogPostTags tags={tags} />
                </div>
              </Col>
            </Row>
          </header>
        </Col>
      </Row>

      {subtitle && (
        <Row justify="center">
          <Col lg={15} xs={24}>
            <div className={styles.subtitle}>{subtitle}</div>
          </Col>
        </Row>
      )}

      <div className={styles.divider} />

      <Row justify="center">
        <Col xxl={10} xl={12} lg={14} md={18} xs={24}>
          <RichText html={html} />
        </Col>
      </Row>
    </article>
  );
}
